:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.agenda-section {
  margin-top: 20px;
  border: 0px;
  padding: 20px;
}
.agenda-section .agenda-title {
  font-size: 20px;
  margin-top: 5px;
}
.agenda-section .agenda-subtitle {
  font-size: 18px;
  margin-top: 5px;
}